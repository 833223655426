/**
 * Page related component is rendered here.
 */

import React from 'react';
import { graphql } from 'gatsby';
import "react-circular-progressbar/dist/styles.css";
import  MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer'
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes';
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders';
import { CarouselResponceData } from '@retina-packages/retina-theme-bootstrap/packages/types/ImageCarouselType';
import { VideoResp } from '@retina-packages/retina-theme-bootstrap/packages/types/VideoGraphResponseType';
import InfoGraphicProgressProvider from '@retina-packages/retina-theme-bootstrap/packages/components/InfographicDonut/ReactCircularProgressbar';
import { PageWrapper, HomePageWrapper } from '@retina-packages/retina-theme-bootstrap/packages/css/CommonStyle'
import { patientHome } from './constants';
import { EsHeaderContainer, FooterCalloutBlock, HomePageContainer, HomePageFooterContainer } from '../../../css/common/style';
import { BrightcoveVideo, HTMLRenderer, Layout, SimpleSlider } from '@retina-packages/retina-theme-bootstrap';
import '../../../css/common/common_es.css'
import { deriveMultiLogo, deriveSiteMenus, footRefHTMLBuilder, fullHTMLDataBuilder, infographicDataBuilder, videoDataBuilder } from '../../../utils';
import retinaConfig from '../../../utils/retinaConfigs';

/** Rendering Template for Primary block
 *
 * @param props props
 * @returns
 */
const PrimaryTemplate = (props: Props) => {
  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso;
  const pgContextsPHM = props.pageContext
  const htmlStaticFilesPHM = {
    nonSVGImages: pgContextsPHM.nonSVGImages,
    svgMediaImages: pgContextsPHM.svgMediaImages,
    allMediaDocument: pgContextsPHM.documents
  }
  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: patientHome.patHomeMainMenu, siteFooterMenu: patientHome.patHomeFooterMenu });
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name;
  const bannerTitle = ""
  const carouselTitle = patientHome.patHomeCarousel
  const footerTitle = patientHome.patFooter
  const exitPopupTitle = patientHome.patExitPopup
  const hcpSwitcherTitle = patientHome.patHCPSwitcher
  const topNavTitle = patientHome.patTopNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs,
    carouselData
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
    carouselData: CarouselResponceData;
  } = reusableDataBuilders({blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle});

  const video1Head = fullHTMLDataBuilder({blocks, title: patientHome.patVideo1Heading})
  const videoData1: VideoResp = videoDataBuilder({ blocks, title: patientHome.patVideo1 });
  const { per, text1, text2 } = infographicDataBuilder({ blocks, title: patientHome.patInfographic });
  const footerCalloutHTML = fullHTMLDataBuilder({ blocks, title: patientHome.patFootrCallout });
  const siteLogos: any = deriveMultiLogo({ blocks, title: patientHome.patSiteLogo});
  const footerRefHTML = footRefHTMLBuilder({ blocks, title: patientHome.patFooterRef });

  return (
    <React.Fragment>
      <HomePageContainer>
        <HomePageFooterContainer className="home-footer-cont">
          <EsHeaderContainer className="eshome">
            <HomePageWrapper className='home-page homewrap'>
              {props.pageContext !== null && props.pageContext.metaInfo !== null && (
                <MetaTagContainer metaData={props.pageContext.metaInfo} />
              )}
              <Layout
                title={"siteTitle"}
                location={props.location}
                modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
                data={mainMenu}
                mdata={footerMenu}
                footerData={footerText}
                audience={audience}
                exitPopData={exitData}
                hcplinks={hcpSwitcher}
                topNavigation={topNavs}
                siteLogos={siteLogos}
                backToTopContent={retinaConfig.backToTopContent}
                staticMediaFiles={htmlStaticFilesPHM}
                gtmBackToTopLabel={patientHome.backToTopGTM}
                hcpValidate={retinaConfig.hcpValidate}
                hcpHomeUrl={retinaConfig.hcpHomeUrl}
                preIndexUrl={retinaConfig.preIndexUrl}
                hcpPrefix={retinaConfig.hcpPrefix}
                siteHomeUrl={retinaConfig.siteHomeUrl}
                footerClassName={patientHome.footerClassName}
                footref={footerRefHTML}
                showRefContent={retinaConfig.showRefContent}
                hideRefContent={retinaConfig.hideRefContent}
                cpraFooter={true}
                cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
                cpraOT={true}
                cpraOTEnv={process.env.OT_ENV}
                cpraOTuuid={process.env.OT_UUID}
                {... {...retinaConfig.layoutAttrs.dtcAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
                >
                <PageWrapper className='pagewrapper homepage-wrapper'>
                  {carouselData && (
                    <>
                      <h1 className='visually-hidden'>{props.pageContext.metaInfo.title.split("|")[0]}</h1>
                      <SimpleSlider simpleImage={true} data={carouselData} {...patientHome.carousalAttrs}/>
                    </>
                  )}
                  <InfoGraphicProgressProvider per={per} text1={text1} text2={text2} />
                  <div className='video-text'>
                    <HTMLRenderer html={video1Head} data={htmlStaticFilesPHM} tagName='div'/>
                  </div>
                  {videoData1 && (
                    <BrightcoveVideo
                      data={videoData1}
                      accountId={retinaConfig.accountId}
                      viewTranscriptBtnContent={retinaConfig.viewTranscriptBtnContent}
                      hideTranscriptBtnContent={retinaConfig.hideTranscriptBtnContent}
                      {...patientHome.video1Attrs}
                      />
                  )}
                  <FooterCalloutBlock className='o-container padding-cards-fix home-page-footer-us'>
                    <HTMLRenderer html={footerCalloutHTML} data={htmlStaticFilesPHM} tagName='div' className='footer-block footer-block-bg' />
                  </FooterCalloutBlock>
                </PageWrapper>
              </Layout>
            </HomePageWrapper>
          </EsHeaderContainer>
        </HomePageFooterContainer>
      </HomePageContainer>
    </React.Fragment>
  )
}

export default PrimaryTemplate;

export const pageQuery = graphql`
  query ($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ... PatientHomePageQuery
      }
    }
  }
`
