/**
 * Definitions of all the constants and paragraph labels are available here.
 */

export const patientHome: any = {
  patHomeMainMenu: "Main Navigational Menu Consumer GCSO - Retina Spain",
  patHomeCarousel: "Homepage Carousal - Retina Spain",
  patFooter: "Footer Consumer - Retina Spain",
  patExitPopup: "Exit Popup - DTC - Retina Spain",
  patHCPSwitcher: "HCP Switcher Modal Pop Up - Retina Spain",
  patTopNav: "Top Navigational Menu Consumer - Retina Spain",
  patVideo1: "Home Brightcove Video Section GCSO - Retina Spain",
  patVideo1Heading: "DTC Home Video Heading Retina Spain",
  patInfographic: "Infographic Percentage Home Consumer GCSO  - Retina Spain",
  patFootrCallout: "Home Footer Callout - HTML DTC - Retina Spain",
  patSiteLogo: "Site logos - Retina Spain",
  patFooterRef: "Homepage Reference - DTC - Retina Spain",
  carousalAttrs: {
    playBtnGTM: {
      'className': 'gtm-link-internal',
      'data-gtm-event-label': 'hero image - play',
      'aria-label': 'Play diapositivas'
    },
    pauseBtnGTM: {
      'className': 'gtm-link-internal',
      'data-gtm-event-label': 'hero image - pause',
      'aria-label': 'Pausar diapositivas'
    },
    btnTexts: {
      'play': 'REPRODUCIR',
      'pause': 'PAUSA'
    },
    playBtnAriaDescription: ' ',
    pauseBtnAriaDescription: ' '
  },
  video1Attrs: {
    viewTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "genes 101 - view transcript",
        "className": "gtm-accordion",
        "aria-label": "Ver la transcripción para Genes 101"
      },
      {
        "data-gtm-event-label": "inheritance 101 - view transcript",
        "className": "gtm-accordion",
        "aria-label": "Ver la transcripción para Herencia 101"
      }
    ],
    hideTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "genes 101 - hide transcript",
        "className": "gtm-accordion",
        "aria-label": "Ver la transcripción para Genes 101"
      },
      {
        "data-gtm-event-label": "inheritance 101 - hide transcript",
        "className": "gtm-accordion",
        "aria-label": "Ver la transcripción para Herencia 101"
      }
    ]
  },
  backToTopGTM: "back to top - homepage",
  footerClassName: "home-footer"
}
